import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import type { Observable } from 'rxjs'
import { shareReplay, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { EventService } from '../event.service'
import { LoggingService } from '../services/logging/logging.service'
import { API_ROUTES } from './api-routes'

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private loginCache$ = this.loginDo().pipe(shareReplay(1))

    constructor(
        private http: HttpClient,
        private logger: LoggingService,
        private eventService: EventService
    ) {}

    public authLogin(
        email: string,
        password: string
    ): Observable<UserResponse> {
        const url =
            API_ROUTES.v0.login +
            `?email=${email}&password=${password}&brand=${environment.brand}`
        return this.getNoLogin<UserResponse>(url).pipe(
            tap((h) => {
                this.eventService.login(h)
                this.logger.addUser(h)
                this.logger.info(
                    'LoginService',
                    'Login ' + h.status + ' registered'
                )
            })
        )
    }

    login(): Observable<XLogin> {
        return this.loginCache$
    }

    private loginDo(): Observable<XLogin> {
        return this.getNoLogin<XLogin>(
            API_ROUTES.v2.anonLogin + '?brand=' + environment.brand
        ).pipe(
            tap((h) => {
                this.eventService.anonLogin(h)
                this.logger.addAnonUser(h)
                this.logger.info(
                    'LoginService',
                    'Login ' + h.status + ' ' + h.login_status
                )
            })
        )
    }

    public getNoLogin<T>(
        url: string,
        paramsVal?: { [param: string]: string }
    ): Observable<T> {
        const httpGetOptions = {
            withCredentials: true,
            params: paramsVal,
        }
        return this.http.get<T>(url, httpGetOptions)
    }
}

export interface XLogin {
    status: string
    brand: string
    id: number
    login_status: string
}
//TODO this interface is poorly typed
export interface UserResponse {
    address_id: null
    avatar_content_type: null
    avatar_file_name: null
    avatar_file_size: null
    avatar_updated_at: null
    brand: string
    carrier_error_code: number
    carrier_name: null
    created_at: string
    email: string
    fb_token: null
    fbid: null
    firstname: string
    homephone: null
    id: number
    is_verified: boolean
    last_login: string
    last_login_attempt: string
    lastname: string
    login_attempts: number
    mobile_country_code: string
    mobile_network_code: null
    mobilephone: string
    name: null
    notes: null
    password_digest: string
    provider: null
    remember_token: string
    reset_password_token: null
    reset_password_within: null
    status: string
    time_zone: string
    trust_level: number
    uid: string
    unconfirmed_email: null
    updated_at: string
    user_type_code: string
    uuid: string
    verification_date: null
    error: string
}

export interface User
    extends Pick<
        UserResponse,
        'email' | 'firstname' | 'lastname' | 'mobilephone' | 'brand' | 'uuid'
    > {}
